































import { Component, Vue } from 'vue-property-decorator';
import { IListView } from '@/lib/interfaces';
import { Logger } from '@/tools/Logger';
import Brand from '@/models/Brand';
import { BrandService } from '@/services';
import BrandsView from './BrandsView.vue';
import BrandsCustomersView from '../BrandsCustomers/BrandsCustomersView.vue';

@Component({
  name: 'BrandsList',
  components: {
    BrandsView,
    BrandsCustomersView,
  },
})

export default class BrandsListPage extends Vue implements IBrandsListData {
  public data: Brand[] = [];

  public selected: Brand[] = [];

  public isDrawerOpen = false;

  // public customers: { id: number; name: string }[] = [];

  protected readonly logger: Logger = new Logger({ context: 'BrandsList' });

  private readonly brandService: BrandService = BrandService.getInstance();

  public async init() {
    try {
      this.data = await this.fetchData();
    } catch (error) {
      this.logger.error(error);
    }
  }

  public created() {
    this.init();
  }

  public async fetchData(): Promise<Brand[]> {
    return this.brandService.api.fetchBrandsWithStatus();
  }

  public toggleCustomersDrawer(isOpen: boolean, brand: Brand) {
    this.isDrawerOpen = isOpen;
    this.selected = [brand];
    // console.log('this.selected', this.selected);
  }
}

interface IBrandsListData extends IListView<Brand> {
  isDrawerOpen: boolean,
  // customers: { id: number; name: string }[],
}
