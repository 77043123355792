




















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CustomerService, UserService, ConnectionRequestService } from '@/services';
import { Logger } from '@/tools/Logger';

@Component({
  name: 'BrandsCustomersView',
})
export default class BrandsCustomersView extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly value!: boolean;

  @Prop({ type: Array, default: () => [] }) public readonly brand!: IBrand[];

  public data: ICustomer[] = [];

  public selected: ICustomer[] = [];

  public isConfirming = false;

  protected readonly logger: Logger = new Logger({ context: 'BrandsCustomersList' });

  private readonly customerService: CustomerService = CustomerService.getInstance();

  private readonly userService: UserService = UserService.getInstance();

  private readonly connectionRequestService: ConnectionRequestService = ConnectionRequestService.getInstance();

  public get drawer() {
    return this.value;
  }

  public set drawer(val: boolean) {
    this.$emit('input', val);
  }

  public get selectedBrand() {
    return this.brand.length > 0 ? this.brand[0] : null;
  }

  public get selectedBrandName() {
    let brand = this.selectedBrand;
    return brand?.name;
  }

  public created() {
    this.init();
  }

  public async init() {
    try {
      this.data = await this.fetchData();
    } catch (error) {
      this.logger.error(error);
    }
  }

  public async fetchData() {
  // public async fetchData(): Promise<Customer[]> {
    const params = {
      authentication_token: this.userService.getActiveToken(),
      connectable: true,
    };

    const response = await this.customerService.api.find(params);

    return response.customers;
  }

  public submitSelectedSites() {
    // console.log('Selected sites:', this.selected);
  }

  public closeDrawer() {
    this.drawer = false;
  }

  public goToConfirm() {
    // console.log('Submitting for brand:', this.brand);
    // console.log('Sites', this.selected);
    if (this.selected.length === 0) {
      this.logger.warn('No sites selected.');
      alert('Please select at least one site.');
      return;
    }
    this.isConfirming = true;
  }

  public goBack() {
    this.isConfirming = false;
  }

  public async confirmSelection() {
    // console.log('Confirmed sites:', this.selected);

    // Array to store the results of successful creations
    const createdConnectionRequests: any[] = [];

    // Loop through the selected sites and process them asynchronously
    for (const site of this.selected) {
      try {
        const params = {
          authentication_token: this.userService.getActiveToken(),
          connection_request: {
            brand_id: this.selectedBrand?.id,
            site_id: site.destination_customer_id,
          },
        };

        const response = await this.connectionRequestService.api.create(params);

        // console.log('response', response);

        // Check for errors in the response (if applicable)
        if (response?.error) {
          // console.error(`Error creating connection for site ${site.name}:`, response.error);
          continue; // Skip this site and proceed to the next
        }

        // Add the successful response to the array
        createdConnectionRequests.push(response);
      } catch (error) {
        // console.error(`Exception occurred while creating connection for site ${site.name}:`, error);
        continue; // Handle errors gracefully and proceed to the next site
      }
    }

    // TODO: Once submitted we need to mark the brand card as pending
    // TODO: Need to investigate if we should be using veux storage for this

    // If no errors occurred, return the array of created objects
    return createdConnectionRequests;
  }

  // public async confirmSelection() {
  //   console.log('Confirmed sites:', this.selected);

  //   // Perform API submission or other logic here
  //   // TODO: Submit request once endpoint becomes available
  //   const params = {
  //     authentication_token: this.userService.getActiveToken(),
  //     brand_id: this.brand?id,
  //     site_id: this.site?identity,
  //   };

  //   const response = await this.connectionRequestService.api.create(params);

  //   return response;
  // }
}

interface ICustomer {
  id: number;
  name: string;
  destination_customer_id: string | number;
}

interface IBrand {
  id: number,
  name: string;
  contact: string;
  admin_email: string;
  logo?: string;
}
